<template>
  <div class="home">
    <my-banner :banner="banner" :nav="nav" />
    <router-view />
  </div>
</template>

<script>
import { queryPhotoManageList } from "@/api/api.js";
export default {
  name: "about",
  data() {
    return {
      banner: "",
      nav: [
        {
          path: "/about/introduction",
          name: "公司介绍",
        },
        {
          path: "/about/corporateCulture",
          name: "企业文化",
        },
        {
          path: "/about/development",
          name: "发展历程",
        },
        {
          path: "/about/staffStyle",
          name: "员工风采",
        },
        {
          path: "/about/qualifications",
          name: "企业资质",
        },
      ],
    };
  },
  created() {
    queryPhotoManageList({
      photoPage: "关于我们",
    }).then((res) => {
      this.banner = res.data.rows[0].photoPicture;
    });
  },
};
</script>
